<template>
  <div class="type-box">
    <div class="title">
      {{ $t('email.name') }}
      <div v-if="def" class="tag ml-1">{{ $t('default') }}</div>
    </div>
    <div class="mt-2">
      {{ email }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'email.setting.component',
  data () {
    return {
      type: 'email',
      def: false
    }
  },
  props: {
    email: String,
    info: Object
  },
  created () {
    if (this.info[this.type] === 1) {
      this.def = true
    }
  },
  methods: {
  }
}
</script>
