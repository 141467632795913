<template>
  <div class="type-box">
    <div class="title">
      {{ $t('google.auth_name') }}
      <div v-if="def" class="tag ml-1">{{ $t('default') }}</div>
    </div>
    <div class="mt-2">
      {{ $t('google.authenticator') }}
    </div>
    <div class="mt-2">
      <div v-if="binding">
        <div :class="[this.def ? 'link' : 'link disabled']" @click="update">
          {{ $t('google.update_code') }}
        </div>
      </div>
      <div v-else>
        <div class="link" @click="setting">
          {{ $t('google.setting_authenticator') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'google.setting.component',
  data () {
    return {
      type: 'google',
      def: false,
      binding: false
    }
  },
  props: {
    email: String,
    info: Object
  },
  created () {
    if (Object.prototype.hasOwnProperty.call(this.info, this.type) && this.info[this.type] === 1) {
      this.def = true
    }
    if (Object.prototype.hasOwnProperty.call(this.info, this.type)) {
      this.binding = true
    }
  },
  methods: {
    setting () {
      this.$router.push({
        name: 'setting.verify',
        params: {
          routerName: 'setting.google.flow'
        }
      })
    },
    update () {
      if (!this.def) {
        return
      }
      this.$router.push({
        name: 'setting.google.reset',
        params: {
          mode: true
        }
      })
    }
  }
}
</script>
