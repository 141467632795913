<template>
  <div class="wrapper">
    <div v-if="loading" class="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else class="card">
      <div class="title">
        <div class="caption">
          <div class="mr-3" style="display: inline-block; cursor:pointer" @click="redirectPage">
            <i class="owl-arrow-left"></i>
          </div>
          {{ $t('two_factor_auth') }}
        </div>
      </div>
      <div class="content">
        <div style="color: #686E74">
          {{ $t('setting.note') }}
        </div>
        <div class="mt-3">
          <EmailSetting :email="email" :info="info"/>
          <SmsSetting class="mt-4" :mobile="mobile" :info="info"/>
          <GoogleSetting class="mt-4" :email="email" :info="info"/>
        </div>
      </div>
      <div class="mt-4">
        <button class="btn btn-ghost btn-blue" @click="alter">{{ $t('setting.alter_auth') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { info, bindInfo } from '../api/2fa'

import EmailSetting from '@/components/email/EmailSetting.vue'
import GoogleSetting from '@/components/google/GoogleSetting.vue'
import SmsSetting from '@/components/sms/SmsSetting.vue'

export default {
  name: 'setting.index',
  data () {
    return {
      loading: false,
      info: {},
      mode: 'setting',
      email: '',
      redirect: '',
      mobile: {
        code: '',
        number: ''
      }
    }
  },
  components: {
    EmailSetting,
    GoogleSetting,
    SmsSetting
  },
  created () {
    if (this.$store.getters.mode !== this.mode) {
      this.$router.push({
        name: 'verify.index'
      })
      return false
    }
    this.init()
  },
  methods: {
    async init () {
      this.loading = true
      const retBindInfo = await bindInfo()
      if (!retBindInfo.data.status) {
        this.$router.push({
          name: 'customer.unused'
        })
        return false
      }
      for (const item in retBindInfo.data.binding) {
        this.info[item.toLowerCase()] = retBindInfo.data.binding[item]
      }
      const retInfo = await info()
      this.email = retInfo.data.customer.email
      this.mobile = retInfo.data.customer.mobile
      this.redirect = retInfo.data.transaction.redirect
      this.loading = false
    },
    alter () {
      this.$router.push({
        name: 'setting.verify',
        params: {
          routerName: 'setting.alter'
        }
      })
    },
    redirectPage () {
      this.$store.dispatch('removeJWT')
      this.$store.dispatch('removeMode')
      localStorage.clear()
      window.location.href = this.redirect
      return true
    }
  }
}
</script>
