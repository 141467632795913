<template>
  <div v-if="status" class="type-box">
    <div class="title">
      {{ $t('sms.name') }}
      <div v-if="def" class="tag ml-1">{{ $t('default') }}</div>
    </div>
    <div class="mt-2">
      ({{ mobile.code }}) {{ mobile.number }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'sms.setting.component',
  data () {
    return {
      type: 'sms',
      def: false,
      status: false
    }
  },
  props: {
    mobile: Object,
    info: Object
  },
  created () {
    this.status = Object.prototype.hasOwnProperty.call(this.info, this.type)
    if (this.status && this.info[this.type] === 1) {
      this.def = true
    }
  },
  methods: {
  }
}
</script>
